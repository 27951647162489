import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	AppBar,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Tab,
	Tabs,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	Check,
	KeyboardArrowRight,
	Person,
	Poll,
	Receipt,
	Tune,
} from '@material-ui/icons';
import {
	eInvoiceCompleted,
	eInvoicePending,
	eInvoiceReject,
	OutletIcon,
} from 'assets';
import DeliveryColorIcon from 'assets/icons/delivery_color.svg';
import OrangeDollarIcon from 'assets/icons/Money/dollar-orange.svg';
import PickupColorIcon from 'assets/icons/pickup_color.svg';
import TimeBlackIcon from 'assets/icons/time_black.svg';
import MenuSchedulingIcon from 'assets/icons/menu_scheduling.svg';
import AppContext from 'containers/App/Store/AppContext';
import {
	PaymentClass,
	useEInvoiceInformationLazyQuery,
	useGetAssignedOutletByUserQuery,
	useGetBillListingSummaryLazyQuery,
	useGetBillOffsetQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { dateFormat, formatTime } from 'helpers/hooks/formatDate';
import { sortDescByKey } from 'helpers/hooks/stringNumberFunction/sortByKey';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFuseSearch } from '../../../helpers/hooks/useSearch';
import './bill.scss';
import { FilterDialog } from './FilterDialog';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import Bill from 'assets/icons/bill-listing.svg';

function a11yProps(index: any) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export const BillListingList = (props: any) => {
	const { mode } = props;

	const history = useHistory();
	const location = useLocation();

	const editData = location?.state as any;

	const [rerender, setRender] = useState(false);
	const [eInvoiceDialog, setEInvoiceDialog] = useState(false);
	const [eInvoiceDialogObj, setEInvoiceDialogObjg]: any = useState();
	const [tabValue, setTabValue] = useState(0);

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const billListingSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const { outletID } = useParams<any>();

	window.onbeforeunload = event => {
		localStorage.removeItem('filterArgs');
	};

	const {
		fetchMore,
		refetch,
		loading: loadingBill,
		data: { getBillInfinite } = { getBillInfinite: [] },
	} = useGetBillOffsetQuery({
		fetchPolicy: 'network-only',
		variables: {
			input: {
				outletID,
			},
			startDate:
				null || JSON.parse(localStorage.getItem('filterArgs'))?.startDate,
			endDate: null || JSON.parse(localStorage.getItem('filterArgs'))?.endDate,
			billNo: '' || JSON.parse(localStorage.getItem('filterArgs'))?.billNo,
			paymentType:
				null || JSON.parse(localStorage.getItem('filterArgs'))?.paymentType,
			customerType:
				null || JSON.parse(localStorage.getItem('filterArgs'))?.customerType,
			tabValue: tabValue,
			offSet: 0,
			limit: 20,
		},
		onCompleted: () => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getAssignedOutletByUser?.filter(
						outlet => outlet?.ID === outletID,
					)[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
			loadBillListingSummary({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const [
		loadEInvoiceInformation,
		{
			loading: EInvoiceInformationLoading,
			data: { EInvoiceInformation } = { EInvoiceInformation: [] },
		},
	] = useEInvoiceInformationLazyQuery({
		fetchPolicy: 'cache-and-network',
	});

	const [
		loadBillListingSummary,
		{
			called: billListingSummaryCalled,
			loading: billListingSummaryLoading,
			data: billListingSummaryData,
		},
	] = useGetBillListingSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem(`searchFilter`);
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	// const {
	// 	data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
	// 	loading: getAssignedOutletByUserLoading,
	// } = useGetOutletQuery();

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const [openSwitch, setOpenSwitch] = useState(null);
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (getBillInfinite && getBillInfinite?.length > 0) {
			setOriginalListing(getBillInfinite);
			setRender(true);
		}
	}, [getBillInfinite]);

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing1(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	const paymentClass = paymentClass => {
		let pymtClass;

		if (paymentClass === PaymentClass.Member) {
			pymtClass = 'Member';
		} else if (paymentClass === PaymentClass.Online) {
			pymtClass = 'Online';
		} else if (paymentClass === PaymentClass.Cash) {
			pymtClass = 'Cash';
		} else if (paymentClass === PaymentClass.Credit) {
			pymtClass = 'Credit';
		} else if (paymentClass === PaymentClass.Debtor) {
			pymtClass = 'Debtor';
		} else if (paymentClass === PaymentClass.Room) {
			pymtClass = 'Room';
		} else if (paymentClass === PaymentClass.NonGuest) {
			pymtClass = 'Non Guest';
		} else if (paymentClass === PaymentClass.SuspendFolio) {
			pymtClass = 'Suspend Folio';
		} else if (paymentClass === PaymentClass.Skipper) {
			pymtClass = 'Skipper';
		} else if (paymentClass === PaymentClass.Officer) {
			pymtClass = 'Officer';
		} else if (paymentClass === PaymentClass.Staff) {
			pymtClass = 'Staff';
		} else if (paymentClass === PaymentClass.Voucher) {
			pymtClass = 'Voucher';
		} else if (paymentClass === PaymentClass.Others) {
			pymtClass = 'Others';
		} else if (paymentClass === PaymentClass.UnsettledBill) {
			pymtClass = 'Unsettled Bill';
		} else if (paymentClass === PaymentClass.BankTt) {
			pymtClass = 'Bank TT';
		} else if (paymentClass === PaymentClass.Cheque) {
			pymtClass = 'Cheque';
		} else if (paymentClass === 'OC') {
			pymtClass = 'OC';
		} else if (paymentClass === 'ENT') {
			pymtClass = 'ENT';
		} else if (paymentClass === 'Discount') {
			pymtClass = 'Discount';
		}

		return pymtClass;
	};

	const handleSort = arr => {
		let temp = arr?.slice();
		return sortDescByKey(temp, 'createdTs');
	};

	useEffect(() => {
		if (
			rerender &&
			!loadingBill &&
			!!billListingSearch?.value &&
			getBillInfinite?.length > 0
		) {
			handleSearch(`'"${billListingSearch?.value ?? ''}"`, [
				'billNo',
				'order.patron.patronClass',
				'order.patronName',
				'payments.defaultPayment.paymentClass',
			]);
		}
	}, [loadingBill, getBillInfinite, rerender]);

	// customer type info line / customer type info line / customer type info line //
	// customer type info line / customer type info line / customer type info line //
	// customer type info line / customer type info line / customer type info line //

	// const customerTypeInfoLine = (patronClass, data) => {
	// 	const patronInfo = data?.order?.patronName;
	// 	if (patronClass === 'Hotel Guest') {
	// 		return ` | ${patronInfo?.replace('| ', '')}`;
	// 	} else if (patronClass === 'Officer' || patronClass === 'Staff') {
	// 		return ` | ${patronInfo?.replace('| ', '')}`;
	// 	} else if (patronClass === 'Member') {
	// 		return ` | Member`;
	// 	} else {
	// 		return ``;
	// 	}
	// };

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const customerTypeInfoLine = (patronClass, order) => {
		if (patronClass === 'Visitor') {
			return order?.patronName ?? 'Visitor';
		} else if (patronClass === 'Hotel Guest') {
			const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === order?.patronAccountID,
			)[0];
			const selectedHotelGuest = getBillInfinite.filter(
				x => x?.orderID === order?.ID,
			)[0];

			return selectedHotelGuest
				? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
						selectedGuest?.GuestName
				  }`
				: 'Hotel Guest';
		} else if (patronClass === 'Staff') {
			return getStaff?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Officer') {
			return getOfficer?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Member') {
			return 'Member';
		}
	};

	const initialValue = {
		startDate:
			JSON.parse(localStorage.getItem('filterArgs'))?.startDate ?? null,
		endDate: JSON.parse(localStorage.getItem('filterArgs'))?.endDate ?? null,
		billNo: JSON.parse(localStorage.getItem('filterArgs'))?.billNo ?? '',
		paymentType:
			JSON.parse(localStorage.getItem('filterArgs'))?.paymentType ?? '',
		customerType:
			JSON.parse(localStorage.getItem('filterArgs'))?.customerType ?? '',
	};

	const [filterValue, setFilterValue] = useState(initialValue);
	const [openFilterDialog, setOpenFilterDialog] = useState(false);

	const triggerFilterDialogOpen = () => {
		setOpenFilterDialog(true);

		if (JSON.parse(localStorage.getItem('filterArgs'))) {
			setFilterValue({
				startDate:
					null || JSON.parse(localStorage.getItem('filterArgs'))?.startDate,
				endDate:
					null || JSON.parse(localStorage.getItem('filterArgs'))?.endDate,
				billNo: '' || JSON.parse(localStorage.getItem('filterArgs'))?.billNo,
				paymentType:
					'' || JSON.parse(localStorage.getItem('filterArgs'))?.paymentType,
				customerType:
					'' || JSON.parse(localStorage.getItem('filterArgs'))?.customerType,
			});
		}
	};

	const triggerFilterDialogClose = () => {
		setOpenFilterDialog(false);
	};

	const eInvoiceInfoDialog = (
		<CommonDialog
			fullWidth={true}
			open={eInvoiceDialog}
			onClose={() => setEInvoiceDialog(false)}
			sections={{
				header: {
					children: (
						<ListItem className="remove-padding">
							<ListItemText
								primary={
									<>
										<span className="xsTitle color-primary-orange flex-space">
											e-Invoice Information
										</span>
									</>
								}
							/>
						</ListItem>
					),
				},
				body: () => (
					<CardContents>
						<>
							{EInvoiceInformation?.map(el => {
								return (
									<>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '10px',
											}}
										>
											<Grid xs={12} item className="xsTitle">
												{el?.docType ?? 'NA'} &nbsp; &nbsp;
												{`(RM ${amtStr(el?.docAmount)})`}
											</Grid>
										</Grid>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '10px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												UIN{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.UIN ?? 'NA'}
											</Grid>
										</Grid>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '5px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												Status{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.eStatus}
											</Grid>
										</Grid>

										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '5px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												Date{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.dateReceive ? dateFormat(el?.dateReceive) : 'NA'}
											</Grid>
										</Grid>
									</>
								);
							})}
						</>
					</CardContents>
				),
				footer: {
					actions: [
						{
							displayText: 'Close',
							props: {
								onClick: () => {
									setEInvoiceDialog(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<>
			{getAssignedOutletByUserLoading && <Loading />}
			{loadingBill && <Loading />}

			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				title={
					getAssignedOutletByUser?.filter(outlet => outlet?.ID === outletID)[0]
						?.name
				}
				// title={user?.companyName}
				routeSegments={[{ name: 'Bill', current: true }]}
			/>

			<SearchHeader
				title="Bill Listing"
				// value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, [
						'billNo',
						'order.patron.patronClass',
						'payments.defaultPayment.paymentClass',
					]);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({ value: e.target.value }),
					);
				}}
				fixed
				// defaultValue={billListingSearch?.value ?? ''}
				// isDefaultValue={!!billListingSearch?.value ?? false}
				search
				onCloseAction={() => {
					handleSearch('', []);
					localStorage.removeItem('filterArgs');
				}}
				option={{
					icon: <Tune />,
					onClick: () => {
						triggerFilterDialogOpen();
					},
				}}
			/>
			<div className="core-tab-bill-listing fixed-search-header-fw">
				<AppBar position="static">
					<Tabs
						aria-label="simple tabs example"
						indicatorColor="primary"
						textColor="primary"
						value={tabValue}
						onChange={handleChange}
						variant={'fullWidth'}
					>
						<Tab
							style={{ width: '50%' }}
							label={`Today (${billListingSummaryData?.billByDateCount
								?.countToday ?? 0}) `}
							{...a11yProps(0)}
						/>
						<Tab
							style={{ width: '50%' }}
							label={`To Date (${billListingSummaryData?.billByDateCount
								?.countToDate ?? 0}) `}
							{...a11yProps(1)}
						/>
					</Tabs>
				</AppBar>
			</div>
			<ContentWrapper dynamicInfoSearch float>
				<InfiniteScroll
					style={{ overflow: 'inherit' }}
					dataLength={getBillInfinite?.length}
					next={() => {
						const currentLength = getBillInfinite?.length;
						console?.log(currentLength, 'currentLength');
						if (currentLength >= 20) {
							fetchMore({
								variables: {
									input: {
										outletID,
									},
									startDate: filterValue?.startDate,
									endDate: filterValue?.endDate,
									billNo: filterValue?.billNo,
									paymentType: filterValue?.paymentType,
									customerType: filterValue?.customerType,
									offSet: currentLength,
									limit: 10,
								},
								updateQuery: (prev: any, { fetchMoreResult }: any) => {
									if (!fetchMoreResult) return prev;

									return Object.assign({}, prev, {
										getBillInfinite: [
											...prev?.getBillInfinite,
											...fetchMoreResult?.getBillInfinite,
										],
									});
								},
							});
						}
					}}
					hasMore={true}
					className=""
					loader={
						loadingBill && (
							<div style={{ textAlign: 'center' }}>
								<CircularProgress />
							</div>
						)
					}
					endMessage={
						<p style={{ textAlign: 'center' }}>
							<b>-</b>
						</p>
					}
				>
					<List className="core-list">
						{!getAssignedOutletByUserLoading &&
						!loadingBill &&
						(getBillInfinite === undefined || getBillInfinite?.length === 0) ? (
							<EmptyList
								title="No Record found"
								subtitle="Please add Bill to continue"
							/>
						) : (
							filteredList?.map((el, index) => {
								let totalT = 0;
								el?.payments
									?.filter(x => x?.status === 'OPEN')
									?.map(x => {
										totalT += x?.receivedAmount - x?.changeAmount;
									});

								let display = el?.payments
									?.filter(v => v?.status === 'OPEN')
									?.map(x => paymentClass(x?.defaultPayment?.paymentClass));

								let display2 = display.join('/');

								return (
									<ListItem>
										<ListItemText
											primary={
												<>
													<span className="flex-space">
														<Grid container>
															<Grid
																className="xsTitle"
																style={
																	el?.status === 'VOID'
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{`#${el?.billNo}`} |{' '}
																{el?.order?.patron?.patronClass}
															</Grid>
															{el?.order?.serviceMode === 'DINE_IN' ? (
																<span
																	style={{
																		backgroundColor: '#FFB11F',
																		boxShadow: 'none',
																		marginLeft: '5px',
																		fontSize: '10px',
																		fontWeight: 'bold',
																		height: '13px',
																		width: 'fit-content',
																		padding: '3px',
																		borderRadius: '3px',
																	}}
																>
																	{el?.order?.table?.prefix}
																	{el?.order?.table?.tableNumber}
																</span>
															) : null}
															{el?.order?.serviceMode === 'PICKUP' ? (
																<img
																	src={PickupColorIcon}
																	style={{
																		width: '16px',
																		height: '16px',
																		paddingLeft: '6px',
																	}}
																/>
															) : null}
															{el?.order?.serviceMode === 'DELIVERY' ? (
																<img
																	src={DeliveryColorIcon}
																	style={{
																		width: '16px',
																		height: '16px',
																		paddingLeft: '6px',
																	}}
																/>
															) : null}
															<Grid
																className="xsTitle"
																style={
																	el?.status === 'VOID'
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{
																	<>
																		{el?.is_einvoice ? (
																			el?.einvoice_irbm_uniqueID ? (
																				<>
																					<span style={{ color: 'white' }}>
																						|
																					</span>
																					| {el?.einvoice_irbm_uniqueID}{' '}
																					<IconButton
																						style={{ padding: '0px' }}
																						onClick={e => {
																							dispatch({
																								type: 'eInvoiceDialogObj',
																								payload: el,
																							});
																							dispatch({
																								type: 'eInvoiceDialog',
																								payload: true,
																							});
																							loadEInvoiceInformation({
																								variables: {
																									outletID: outletID,
																									ID: el?.ID,
																									type: 'Bill',
																								},
																							});
																						}}
																					>
																						{el?.einvoice_status === 'NEW' ||
																						el?.einvoice_status ===
																							'RECORDED' ||
																						el?.einvoice_status ===
																							'PENDING_VALIDATION' ? (
																							<img
																								src={eInvoicePending}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						) : el?.einvoice_status ===
																								'VALIDATED' ||
																						  el?.einvoice_status ===
																								'REJECT_REQUEST' ||
																						  el?.einvoice_status ===
																								'CONSOLIDATED' ? (
																							<img
																								src={eInvoiceCompleted}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						) : (
																							<img
																								src={eInvoiceReject}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						)}
																					</IconButton>{' '}
																				</>
																			) : (
																				<>
																					<span style={{ color: 'white' }}>
																						|
																					</span>
																					| NA{' '}
																					<IconButton
																						style={{ padding: '0px' }}
																						onClick={e => {
																							setEInvoiceDialogObjg(el);
																							setEInvoiceDialog(true);
																							loadEInvoiceInformation({
																								variables: {
																									outletID: outletID,
																									ID: el?.ID,
																									type: 'Bill',
																								},
																							});
																						}}
																					>
																						{el?.einvoice_status === 'NEW' ||
																						el?.einvoice_status ===
																							'RECORDED' ||
																						el?.einvoice_status ===
																							'PENDING_VALIDATION' ? (
																							<img
																								src={eInvoicePending}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						) : el?.einvoice_status ===
																								'VALIDATED' ||
																						  el?.einvoice_status ===
																								'REJECT_REQUEST' ||
																						  el?.einvoice_status ===
																								'CONSOLIDATED' ? (
																							<img
																								src={eInvoiceCompleted}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						) : (
																							<img
																								src={eInvoiceReject}
																								alt="svg"
																								style={{ width: '25px' }}
																							/>
																						)}
																					</IconButton>{' '}
																				</>
																			)
																		) : (
																			''
																		)}
																	</>
																}
															</Grid>
														</Grid>
													</span>

													<span
														className="xsTitle"
														style={
															el?.status === 'VOID'
																? { textDecoration: 'line-through' }
																: null
														}
													>
														{display2 ? display2 : el?.billPaymentType}
														{/* {el?.billPaymentType} */}
													</span>
												</>
											}
											secondary={
												<>
													<span className="flex-space desc">
														<img
															src={TimeBlackIcon}
															style={{
																width: '10px',
																height: '10px',
																paddingRight: '3px',
															}}
														/>
														<span className="desc">
															{formatTime(el?.createdTs)}
														</span>

														<TextSeparator />
														<img
															src={MenuSchedulingIcon}
															style={{
																width: '10px',
																height: '10px',
																paddingRight: '3px',
																marginBottom: '-1px',
															}}
														/>
														<span className="desc">
															{dateFormat(el?.createdTs)}
														</span>
														<TextSeparator />

														<span className="desc">
															{customerTypeInfoLine(
																el?.order?.patron?.patronClass,
																el?.order,
															)}
														</span>

														{el?.documentType === 'CN' && (
															<>
																<TextSeparator />
																<span
																	className="desc"
																	style={{ fontWeight: 'bold', color: 'black' }}
																>
																	<Tooltip
																		title={
																			<span className="icon-text desc">
																				<Receipt />
																				{el?.refBillNo}
																				<br />
																				{/* {dateFormat(i?.createdTs, 'dd mmm yyyy h:MM TT')} */}
																			</span>
																		}
																		placement="right-end"
																	>
																		<span
																			className="desc"
																			style={{
																				fontWeight: 'bold',
																				color: 'black',
																			}}
																		>
																			<img
																				src={Bill}
																				alt="ifca"
																				width={12}
																				loading="lazy"
																				style={{ paddingRight: '4px' }}
																			/>
																			{/* {el?.refBillNo} */}
																		</span>
																	</Tooltip>
																</span>
															</>
														)}
													</span>

													<span
														className={'xsTitle color-primary-orange'}
														style={{
															...(el?.status === 'VOID'
																? { textDecoration: 'line-through' }
																: null),
														}}
													>
														{`${getAssignedOutletByUser?.filter(
															outlet => outlet?.ID === outletID,
														)[0]?.currencyCode ?? ''}${amtStr(totalT)}`}
													</span>
												</>
											}
											// onClick={() =>
											// 	history.push(
											// 		`/menu/outlet-app/${outletID}/bill-listing/${el?.ID}/bill-detail`,
											// 	)
											// }
										/>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													onClick={() =>
														history.push(
															`/menu/outlet-app/${outletID}/bill-listing/${el?.ID}/bill-detail`,
														)
													}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})
						)}
					</List>
				</InfiniteScroll>
			</ContentWrapper>
			{eInvoiceInfoDialog}
			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<TextField
									// className="m-b-20"
									id="standard-basic"
									label="Search here..."
									fullWidth
									onChange={e => {
										handleSearch1(`'"${e?.target?.value}"`, ['name']);
									}}
								/>
							) : null}
							<ContentWrapper style={{ marginTop: '0px' }}>
								<List>
									{filteredList1?.map((outlet, index) => (
										<>
											<ListItem
												className={
													outlet?.ID === outletID ? 'bg-blue-light' : null
												}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/bill-listing`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>

			<FilterDialog
				setFilterValue={setFilterValue}
				filterValue={filterValue}
				initialValue={initialValue}
				refetch={refetch}
				open={openFilterDialog}
				close={triggerFilterDialogClose}
			/>
		</>
	);
};
