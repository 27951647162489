import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
	PaymentDialogComponent,
	SignatureDialog,
} from 'components/Dialog/PaymentDialogComponent';
import { BillFooter } from 'components/Footer/BillFooter';
import { TakeawayFooter } from 'components/Footer/TakeawayFooter';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CreditCardType,
	GetOrderItemDocument,
	GetAllOrderItemDocument,
	OrderItemStatus,
	PaymentClass,
	PosPermission,
	useCancelTakeawayOrderMutation,
	useCreatePaymentByQuantityMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetDefaultPaymentQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOutletQuery,
	useGetReasonLazyQuery,
	useGetTakeawayOrderLazyQuery,
	useGetAllOrderItemLazyQuery,
	usePickupTakeawayOrderMutation,
	useVoidOrderItemWithOptionsMutation,
	useVoidOrderItemWithSetMenuOptionsMutation,
	OrderItemDiscountStatus,
	useCreateOnlinePaymentMutation,
	useGetRoundingPolicyQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { amtNumStr, amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import './takeaway.scss';
import BigNumber from 'bignumber.js';

export interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
}

interface OrderItemTax {
	taxAmount: number;
	taxSchemeType: string;
}

interface OrderItemOptionItemTax {
	taxAmount: number;
}

interface OrderItemDiscountTax {
	taxAmount: number;
}

interface OrderItemOptionItemDiscountTax {
	taxAmount: number;
}

interface OrderDiscountItemTax {
	taxAmount: number;
}
interface orderDiscountItemOptionTax {
	taxAmount: number;
}

interface OrderDiscountItem {
	orderItemID?: string;
	baseAmount: number;
}

interface OrderItemDiscount {
	orderItemID?: string;
	baseAmount: number;
	status: string;
}

interface OrderItemOptionItem {
	optionItemID: string;
	price: number;
	basePrice: number;
	unitPrice: number;
	orderItemOptionItemTax: OrderItemOptionItemTax[];
	discountBaseAmount: number;
	orderItemOptionItemDiscountTax: OrderItemOptionItemDiscountTax[];
	orderDiscountItemOptionTax: orderDiscountItemOptionTax[];
}

interface OrderItemOption {
	optionID: string;
	orderItemOptionItem: OrderItemOptionItem[];
}

interface Item {
	ID: string;
	amount: number;
	orderItemTax: OrderItemTax[];
	orderItemDiscount?: OrderItemDiscount;
	orderItemDiscountTax: OrderItemDiscountTax[];
	orderDiscountItem?: OrderDiscountItem;
	orderDiscountItemTax?: OrderDiscountItemTax[];
	orderItemOption?: OrderItemOption[];
}

export const TakeawayConfirmOrder = (props: any) => {
	useEffect(() => {
		console.log('TakeawayConfirmOrder');
	}, []);
	const history = useHistory();
	const { outletID, orderID } = useParams<Record<string, any>>();

	const [openDialog, setOpenDialog] = useState(false);
	const { mode } = props;
	const [pickupDialog, setPickupDialog] = useState(false);
	const [cancelDialog, setCancelDialog] = useState(false);
	const [paymentDialog, setPaymentDialog] = useState(false);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [selectedNonGuest, setSelectedNonGuest] = useState({} as any);
	const [selectedFolio, setSelectedFolio] = useState({} as any);
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedDebtor, setSelectedDebtor] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	const [defaultNonGuest, setDefaultNonGuest] = useState({} as any);
	const [defaultFolio, setDefaultFolio] = useState({} as any);
	const [defaultDebtor, setDefaultDebtor] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	const [receivedAmount, setReceivedAmount] = useState(0);
	const [minVal, setMinVal] = useState(0); //minimum payment
	const [changeAmount, setChangeAmount] = useState(0); //change amount
	const [selectedCredit, setSelectedCredit] = useState({} as any);

	const [openPayment, setOpenPayment] = useState<boolean>(false);
	const [openSignature, setOpenSignature] = useState<boolean>(false);

	const [itemArray, setItemArray] = useState([]);
	const [negOrderItem, setNegOrderItem] = useState({});

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { handlePermDisabled } = usePermissionChecker();

	const {
		anchorEl: anchorElDialog,
		menu,
		handleClick: handleClickDialog,
		handleClose: handleCloseDialog,
	} = useMenuOption();

	const [deductionQuantity, setDeductionQuantity] = useState<number>(1);

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (deductionQuantity === menu?.obj?.quantity) {
				setDeductionQuantity(menu?.obj?.quantity);
			} else if (
				deductionQuantity ===
				menu?.obj?.quantity - negOrderItem[menu?.obj?.ID]
			) {
				setDeductionQuantity(menu?.obj?.quantity - negOrderItem[menu?.obj?.ID]);
			} else {
				setDeductionQuantity(deductionQuantity + 1);
			}
		} else {
			if (deductionQuantity === 1) {
				setDeductionQuantity(1);
			} else {
				setDeductionQuantity(deductionQuantity - 1);
			}
		}
	};

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const [
		loadTakeawayOrder,
		{
			loading: takeawayOrderLoading,
			data: { getTakeawayOrder } = { getTakeawayOrder: [] },
		},
	] = useGetTakeawayOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getTakeawayOrder }) => {
			setSelectedOrder(getTakeawayOrder[0]);
		},
	});

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
			loadTakeawayOrder({
				variables: {
					outletID: outletID,
					orderID: orderID,
				},
			});
		},
	});

	const [selectedOrder, setSelectedOrder] = useState(null);

	function generateRoundingAmount(
		totalFooter: number,
		roundingPolicyAmount: number,
	): number {
		const tempCents = totalFooter - Math.floor(totalFooter); // 16.24 - 16.00 = 0.24

		const cents = Math.round((tempCents + Number.EPSILON) * 100) / 100; // 0.24

		const roundingMulti = Math.round(
			(cents * 100) / (roundingPolicyAmount! * 100), // (0.24 * 100) / (0.05 * 100) = 24 / 5 = Math.round(4.8) = 5
		);

		const roundedAmount =
			getRoundingPolicy[0]?.inUse === true
				? Math.floor(totalFooter) + roundingPolicyAmount! * roundingMulti
				: totalFooter; // 16.00 + 0.05 * 5 = 16.

		const roundingAmount = (totalFooter - roundedAmount) * -1; // 16.24 - 16.25 = 0.01

		return Number(roundingAmount.toFixed(2));
	}

	const [
		loadOrderItem,
		{
			loading: getAllOrderItemLoading,
			called: getAllOrderItemCalled,
			data: { getAllOrderItem } = {
				getAllOrderItem: [],
			},
			fetchMore,
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				el?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });
			setItemArray(
				unpaidOrderItem
					?.map(el => {
						if (
							el?.quantity - temp[el?.ID]?.quantity === 0 ||
							el?.quantity < 0
						) {
							return { ...el, void: true };
						} else {
							return { ...el, void: false };
						}
					})
					?.filter(list => list.void === false),
				// ?.filter(list2 => list2.quantity > 0), // CAUSE HERE WE WANNA SEE THE SLASH2 ONE
			);
		},
	});

	const orderItems = getTakeawayOrder[0]?.orderItem
		?.map(el => {
			if (
				el?.quantity - negOrderItem?.[el?.ID]?.quantity === 0 ||
				el?.quantity < 0
			) {
				return { ...el, void: true };
			} else {
				return { ...el, void: false };
			}
		})
		?.filter(list => list.void === false);

	let ttl,
		subtotal,
		finalTax,
		total,
		discount = 0,
		totalOptItm,
		discOptItm,
		optTaxAmount,
		optServiceCharge,
		discOptTaxAmount,
		discOptServiceCharge,
		orderDiscount,
		roundingAmount;

	if (orderItems?.length > 0) {
		const itemArray = getAllOrderItem;

		const filterItemArray: {
			ID: string;
			amount: number;
			orderItemTax: OrderItemTax[];
			orderItemDiscount?: OrderItemDiscount;
			orderItemDiscountTax: OrderItemDiscountTax[];
			orderDiscountItem?: OrderDiscountItem;
			orderDiscountItemTax?: OrderDiscountItemTax[];
			orderItemOption?: OrderItemOption[];
		}[] = itemArray?.map(
			({
				ID,
				amount,
				orderItemTax,
				orderItemDiscount,
				orderItemDiscountTax,
				orderDiscountItem,
				orderDiscountItemTax,
				orderItemOption,
			}) => {
				const activeOID = orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];

				return {
					ID,
					amount,
					orderItemTax: orderItemTax?.map(({ taxAmount, taxSchemeType }) => ({
						taxAmount,
						taxSchemeType,
					})),
					orderItemDiscount: {
						baseAmount: activeOID?.baseAmount ?? 0,
						status: activeOID?.status ?? '',
						orderItemID: ID,
					},
					orderItemDiscountTax: orderItemDiscountTax?.map(({ taxAmount }) => ({
						taxAmount,
					})),
					orderDiscountItem: {
						baseAmount: orderDiscountItem?.baseAmount ?? 0,
						orderItemID: ID,
					},
					orderDiscountItemTax: orderDiscountItemTax?.map(({ taxAmount }) => ({
						taxAmount,
					})),
					orderItemOption: orderItemOption?.map(
						({ optionID, orderItemOptionItem }) => ({
							optionID,
							orderItemOptionItem: orderItemOptionItem.map(
								({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax,
									discountBaseAmount,
									orderItemOptionItemDiscountTax,
									orderDiscountItemOptionTax,
								}) => ({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax: orderItemOptionItemTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									discountBaseAmount,
									orderItemOptionItemDiscountTax: orderItemOptionItemDiscountTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									orderDiscountItemOptionTax: orderDiscountItemOptionTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									// Include other properties if needed
								}),
							),
							// Include other properties from OrderItemOption if needed
						}),
					),
				};
			},
		);

		//subtotal amount
		const sumAmount = (items: Item[]): number => {
			let totalBaseAmount = 0;
			for (const item of items) {
				totalBaseAmount += item.amount;
			}
			return totalBaseAmount;
		};

		const totalAmount = sumAmount(filterItemArray);

		//subtotal tax amount

		const sumTaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemTax) {
					totalTax += tax?.taxAmount;
				}
			}
			return totalTax;
		};

		const totalTaxAmount = sumTaxAmount(filterItemArray);
		const roundedTotalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

		//subtotal order discount item base amount

		const sumODIBaseAmount = (items: Item[]): number => {
			let totalODIBaseAmt = 0;
			for (const item of items) {
				totalODIBaseAmt += item?.orderDiscountItem?.baseAmount;
			}
			return totalODIBaseAmt;
		};

		const totalODIBaseAmount = sumODIBaseAmount(filterItemArray);

		//subtotal order discount item tax amount

		const sumODITaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderDiscountItemTax)
					totalTax += tax?.taxAmount;
			}
			return totalTax;
		};

		const totalODITaxAmount = sumODITaxAmount(filterItemArray);
		const roundedTotalODITaxAmount = parseFloat(totalODITaxAmount.toFixed(2));

		//subtotal order item discount base amount

		const sumOIDBaseAmount = (items: Item[]): number => {
			let totalOIDBaseAmt = 0;
			for (const item of items) {
				totalOIDBaseAmt += item?.orderItemDiscount?.baseAmount;
			}
			return totalOIDBaseAmt;
		};

		const totalOIDBaseAmount = sumOIDBaseAmount(filterItemArray);

		//subtotal order item discount tax amount

		const sumOIDTaxAmount = (items: Item[]): number => {
			let totalOIDTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemDiscountTax)
					totalOIDTax += tax?.taxAmount;
			}
			return totalOIDTax;
		};

		const totalOIDTaxAmount = sumOIDTaxAmount(filterItemArray);
		const roundedTotalOIDTaxAmount = parseFloat(totalOIDTaxAmount.toFixed(2));

		const sumOptionItemOverallField = (
			items: Item[],
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const totalOptItmOverallAmount = sumOptionItemOverallField(filterItemArray);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		//tax
		finalTax =
			roundedTotalTaxAmount +
			optTaxAmount -
			roundedTotalODITaxAmount -
			roundedTotalOIDTaxAmount -
			discOptTaxAmount;

		//subtotal
		subtotal = totalAmount + roundedTotalTaxAmount + totalOptItm;

		//discount
		let ODI = totalODIBaseAmount + roundedTotalODITaxAmount;

		let OID = totalOIDBaseAmount + roundedTotalOIDTaxAmount;

		discount = ODI + OID + discOptItm;

		//total
		total = subtotal - discount;

		roundingAmount =
			getRoundingPolicy[0]?.inUse === true
				? generateRoundingAmount(
						total,
						getRoundingPolicy[0]?.smallestDenomination,
				  )
				: 0;
	}

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		voidOrderItemWithOptions,
		{
			data: voidedOrderItemWithOptions,
			loading: voidedOrderItemWithOptionsLoading,
		},
	] = useVoidOrderItemWithOptionsMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
		},
	});

	const [
		voidOrderItemWithSetMenuOptions,
		{
			data: voidedOrderItemWithSetMenuOptions,
			loading: voidedOrderItemWithSetMenuOptionsLoading,
		},
	] = useVoidOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
		},
	});

	const onSubmit = data => {
		if (menu?.obj?.menuItem?.isSetMenu === false) {
			voidOrderItemWithOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
				],
			});
		} else if (menu?.obj?.menuItem?.isSetMenu === true) {
			voidOrderItemWithSetMenuOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
				],
			});
		}
		setOpenDialog(false);
	};

	const [fs, setFS] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);
	const [ro, setRo] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setTa(finalTax);
		setFD(discount);
		setTo(total + roundingAmount);
		setRo(roundingAmount);
	}, [subtotal, finalTax, discount, total, roundingAmount]);

	const [radioError, setRadioError] = useState(false);

	const handleRadio = e => {
		setValue('defaultPaymentID', e.target.value);
	};

	//yupppp
	const yupSchema = yup.object().shape({
		receivedAmount: yup
			.number()
			// .integer()
			.min(minVal, `Amount cannot be less than bill amount`)
			.typeError('Amount cannot be empty')
			.nullable(),
	});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
		getValues,
		reset,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const {
		called: paymentCalled,
		loading: paymentLoading,
		error: paymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const [
		createOnlinePayment,
		{ loading: createOnlinePaymentLoading },
	] = useCreateOnlinePaymentMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ createOnlinePayment }) => {},
	});

	const memberHotelRadioID = watch => {
		if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Member)[0]
				?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Room)[0]
				?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass.NonGuest,
			)[0]?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass.SuspendFolio,
			)[0]?.ID
		) {
			return true;
		} else return false;
	};

	const [
		createPaymentByQuantity,
		{ loading: paymentQuantityLoading },
	] = useCreatePaymentByQuantityMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ paymentByQuantity }) => {
			history.push(
				`/menu/outlet-app/${outletID}/order/${orderID}/bill-settlement/${paymentByQuantity?.ID}/takeaway`,
			);
			setRadioError(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		loadReason,
		{
			loading: reasonLoading,
			error: reasonError,
			called: reasonCalled,
			data: { getReason } = { getReason: [] },
		},
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	let cardType: any = watch('cardType');
	let cardNo: any = watch('cardReference');

	const orderItemIDs = itemArray?.map(v => {
		return {
			orderItemID: v?.ID,
			paymentQuantity: v?.quantity,
		};
	});

	const selectedPaymentClass = () => {
		const name = getDefaultPayment?.filter(
			x => x?.ID === watch('defaultPaymentID'),
		)[0]?.paymentClass;
		return name;
	};

	const creditCardEnum = data => {
		let credClass;
		if (data === 'American Express Card') {
			credClass = CreditCardType.AmericanExpressCard;
		} else if (data === 'Diners Card') {
			credClass = CreditCardType.DinersCard;
		} else if (data === 'JCB Card') {
			credClass = CreditCardType.JcbCard;
		} else if (data === 'Master Card') {
			credClass = CreditCardType.Mastercard;
		} else if (data === 'UNION PAY') {
			credClass = CreditCardType.UnionpayCard;
		} else if (data === 'Visa Card') {
			credClass = CreditCardType.VisaCard;
		} else if (data === 'Debit Card') {
			credClass = CreditCardType.DebitCard;
		} else if (data === 'E-Wallet') {
			credClass = CreditCardType.Ewallet;
		}
		return credClass;
	};

	const [hotelGuestInfo, setHotelGuestInfo] = useState({} as any);

	useEffect(() => {
		if (selectedRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedRoom);
		} else if (selectedNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedNonGuest);
		} else if (selectedFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedFolio);
		} else if (selectedDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedDebtor);
		} else if (defaultRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultRoom);
		} else if (defaultNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultNonGuest);
		} else if (defaultFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultFolio);
		} else if (defaultDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultDebtor);
		}
	}, [
		selectedRoom,
		defaultRoom,
		selectedNonGuest,
		defaultNonGuest,
		selectedFolio,
		defaultFolio,
		defaultDebtor,
		selectedDebtor,
	]);

	const saveRounding = (defaultPayment, v, index, lastIndex) => {
		let roundingAmount;
		const gotCash =
			defaultPayment?.filter(x => x?.paymentClass === PaymentClass?.Cash)
				?.length > 0;

		if (gotCash && v?.paymentClass === PaymentClass.Cash) {
			roundingAmount = ro;
		}

		if (gotCash === false && index === lastIndex) {
			roundingAmount = ro;
		}

		return roundingAmount;
	};
	const [lastArray, setLastArray] = useState([]);

	let paymentInputArr = [];
	const onSubmitPayment = async (data: any, files?) => {
		const ref = selectedOrder?.docNo;
		let billInput;
		let totalReceiveAmount = 0;

		console.log('data', data);

		const lastIndex =
			data?.defaultPayment?.filter(x => x?.selected === true)?.length - 1;

		data?.defaultPayment
			?.filter(x => x?.selected === true)
			?.map((v, index) => {
				billInput = {
					orderID: orderID,
					outletID: outletID,
					...(v?.paymentClass === PaymentClass.Room ||
					v?.paymentClass === PaymentClass.Member ||
					v?.paymentClass === PaymentClass.NonGuest ||
					v?.paymentClass === PaymentClass.Debtor ||
					v?.paymentClass === PaymentClass.SuspendFolio
						? { file: files }
						: null),
				};

				let paymentInput = {
					outletID: outletID,
					amount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount
							: v?.amountPayment,
					creditCardType:
						cardType?.GuestType !== null && cardType?.GuestType !== undefined
							? creditCardEnum(cardType?.GuestType)
							: cardType,
					cardNo: v?.cardRef,
					// paidAmount: v?.amountPayment,
					changeAmount:
						v?.paymentClass === PaymentClass?.Cash
							? changeAmount >= 0
								? Math.round((Math.abs(changeAmount) + Number.EPSILON) * 100) /
								  100
								: 0
							: 0,
					receivedAmount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount !== 0
								? receivedAmount
								: v?.amountPayment
							: 0,
					roundingAmount:
						saveRounding(data?.defaultPayment, v, index, lastIndex) ?? 0,
					defaultPaymentID: v?.defaultPaymentID,
					paymentClass: v?.paymentClass,
					patronAccountID:
						selectedOfficer?.ID !== (null || undefined) ||
						selectedStaff?.ID !== (null || undefined)
							? selectedOfficer?.ID ?? selectedStaff?.ID
							: defaultOfficer?.ID ?? defaultStaff?.ID,
					registrationID:
						v?.paymentClass === PaymentClass?.Room
							? selectedRoom?.RegistrationID ?? defaultRoom?.RegistrationID
							: v?.paymentClass === PaymentClass?.NonGuest
							? selectedNonGuest?.RegistrationID ??
							  defaultNonGuest?.RegistrationID
							: v?.paymentClass === PaymentClass?.SuspendFolio
							? selectedFolio?.RegistrationID ?? defaultFolio?.RegistrationID
							: v?.paymentClass === PaymentClass?.Debtor
							? selectedDebtor?.RegistrationID ?? defaultDebtor?.RegistrationID
							: cardType?.RegistrationID,

					hotelGuestInfo: {
						BookingNo: hotelGuestInfo?.BookingNo,
						FullName: hotelGuestInfo?.GuestName,
						RoomNo: hotelGuestInfo?.RoomNo,
						GuestType: hotelGuestInfo?.GuestType,
						ArrivalDate: hotelGuestInfo?.ArrivalDate,
						DepartureDate: hotelGuestInfo?.DepartureDate,
						DebtorAccount: hotelGuestInfo?.DebtorAccount,
						CompanyName: hotelGuestInfo?.CompanyName,
					},
					referenceNo: v?.referenceNo,
					remark: v?.remark,
					billLedgerInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						RegistrationID:
							selectedRoom?.RegistrationID ??
							selectedNonGuest?.RegistrationID ??
							selectedFolio?.RegistrationID ??
							defaultRoom?.RegistrationID ??
							defaultNonGuest?.RegistrationID ??
							defaultFolio?.RegistrationID,
						OutletCode: getOutlet[0]?.outletCode,
						IncidentalChargeID: '',
						Reason:
							v?.paymentClass === PaymentClass?.Room
								? 'MenuX - Charge To Room'
								: v?.paymentClass === PaymentClass?.NonGuest
								? 'MenuX - Charge To Non Guest'
								: v?.paymentClass === PaymentClass?.SuspendFolio
								? 'MenuX - Charge To Suspend Folio'
								: v?.paymentClass === PaymentClass?.Debtor
								? 'MenuX - Charge To Debtor'
								: v?.paymentClass === PaymentClass?.Credit
								? 'MenuX - Charge To Credit'
								: '',
						Description: `${getOutlet[0]?.outletCode} -  ${ref}`,
						TrxDate: new Date(),
						Qty: 1,
						UnitPrice: parseFloat(amtNumStr(v?.amountPayment)),
						TrxAmount: parseFloat(amtNumStr(v?.amountPayment)),
						BaseAmount: parseFloat(amtNumStr(v?.amountPayment)),
						ServiceCharge: parseFloat(amtNumStr(0)),
						TaxAmount: parseFloat(amtNumStr(0)),
						DiscountAmount: 0,
						IsChargeToRoom: true,
						ReferenceNo: ref,
						CreatedBy: user?.ID,
					},
					scheduleBillingInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						BillNo: ref,
						CardNo: v?.cardRef,
						BillDate: new Date(),
						BillAmount: v?.amountPayment,
						// BaseAmount: footerState?.subtotal,
						DebtorID:
							v?.paymentClass === PaymentClass?.Credit
								? cardType?.RegistrationID
								: v?.registrationID,
						CreatedBy: user?.ID,
						// RegistrationID:
						// 	defaultDebtor?.RegistrationID ??
						// 	selectedDebtor?.RegistrationID ??
						// 	cardType?.RegistrationID,
					},
				};
				paymentInputArr?.push(paymentInput);
			});

		paymentInputArr?.map(x => {
			totalReceiveAmount += Number(
				x?.paymentClass === PaymentClass.Cash ? x?.receivedAmount : x?.amount,
			);
		});

		console.log('paymentInputArr', paymentInputArr);

		setLastArray(paymentInputArr);

		createPaymentByQuantity({
			variables: {
				paymentByQuantityInput: orderItemIDs,
				billInput,
				paymentInput: paymentInputArr,
				// billLedgerInput,
				// scheduleBillingInput,
			},
			refetchQueries: [
				{
					query: GetOrderItemDocument,
					variables: {
						orderID: selectedOrder?.ID,
						status: OrderItemStatus.Confirmed,
					},
				},
				{
					query: GetAllOrderItemDocument,
					variables: { orderID: selectedOrder?.ID },
				},
			],
		});
	};

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { globalState, dispatch }: any = useContext(AppContext as any);

	useEffect(() => {
		if (!getAllOrderItemCalled) {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
		}
	}, [getAllOrderItemCalled]);

	const [
		pickupTakeawayOrder,
		{ data: pickedupTakeawayOrder, loading: loadingPickupTakeawayOrder },
	] = usePickupTakeawayOrderMutation({
		onCompleted: data => {
			setPickupDialog(false);
			history.push(`/menu/outlet-app/${outletID}/takeaway`);
		},
	});

	const onSubmitPickup = async () => {
		pickupTakeawayOrder({
			variables: {
				orderID: orderID,
			},
			refetchQueries: [
				{
					query: GetOrderItemDocument,
					variables: { orderID: orderID },
				},
			],
		});
	};

	const [
		cancelTakeawayOrder,
		{ data: canceledTakeawayOrder, loading: loadingCancelTakeawayOrder },
	] = useCancelTakeawayOrderMutation({
		onCompleted: data => {
			setPickupDialog(false);
			history.push(`/menu/outlet-app/${outletID}/takeaway`);
		},
	});

	const onSubmitCancel = async () => {
		cancelTakeawayOrder({
			variables: {
				orderID: orderID,
			},
			refetchQueries: [
				{
					query: GetOrderItemDocument,
					variables: { orderID: orderID },
				},
			],
		});
	};

	const color = (fields, loading) => {
		let color: any = 'primary';

		if (fields === true && loading === false) {
			color = 'inherit';
		} else if (fields === false && loading === false) {
			color = 'primary';
		} else if (fields === false && loading === true) {
			color = 'inherit';
		}

		return color;
	};

	const disable = (fields, loading) => {
		let disable: any = false;

		if (fields === true && loading === false) {
			disable = true;
		} else if (fields === false && loading === false) {
			disable = false;
		} else if (fields === false && loading === true) {
			disable = true;
		}

		return disable;
	};

	return (
		<>
			{takeawayOrderLoading && <Loading />}
			{outletLoading && <Loading />}
			{paymentLoading && <Loading />}
			{getAllOrderItemLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={() => {
					history.push(`/menu/outlet-app/${outletID}/takeaway`);
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Confirm Order', current: true },
				]}
				rightRouteSegments={[
					{
						name:
							!getCurrentMealPeriodLoading &&
							(currentMealPeriod
								? `${currentMealPeriod?.name} ${formatTime(
										currentMealPeriod?.startTime,
								  )} - ${formatTime(currentMealPeriod?.endTime)}`
								: `No applicable meal period at the moment`),
					},
				]}
			/>
			<OrderSubheader
				mode={mode}
				getOrderLoading={takeawayOrderLoading}
				getOrder={getTakeawayOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				// handleChangeOrder={handleChangeOrder}
				outletID={outletID}
			/>
			<ContentWrapper multiLineSubheader>
				{!takeawayOrderLoading &&
					!outletLoading &&
					!paymentLoading &&
					!getAllOrderItemLoading &&
					!getCurrentMealPeriodLoading &&
					(itemArray?.length === 0 ? (
						<EmptyList
							title="There are no orders found"
							subtitle="Please make a new order in the Menu "
						/>
					) : (
						<>
							<List style={{ backgroundColor: 'white', color: 'black' }}>
								{itemArray?.map((el, index) => {
									const isInclusive = getOutlet[0]?.isInclusive;

									const baseAmountT = el?.taxAmount;

									const baseAmount = el?.amount;

									//Order Item Sum Up
									let totalTax = 0;
									el?.orderItemTax?.map(x => {
										totalTax += x?.taxAmount;
									});
									const baseItemAmountBill = el?.amount + totalTax;

									const negBaseItemAmountBill =
										(negOrderItem?.[el?.ID]?.amount ?? 0) +
										(negOrderItem?.[el?.ID]?.taxAmount ?? 0);

									const hasItemDisc =
										el?.orderDiscountItem &&
										el?.orderDiscountItem?.amount !== 0;

									const activeOID = el?.orderItemDiscount?.filter(
										x => x?.status === OrderItemDiscountStatus.Active,
									)[0];

									let totalOIDTax = 0;
									el?.orderItemDiscountTax?.map(x => {
										totalOIDTax += x?.taxAmount;
									});

									const oidBA = activeOID?.baseAmount;
									const oidTx = totalOIDTax;

									const oid = oidBA + oidTx;

									let totalODITax = 0;
									el?.orderDiscountItemTax?.map(x => {
										totalODITax += x?.taxAmount;
									});

									const odiTx = totalODITax;
									const odi = el?.orderDiscountItem?.baseAmount + odiTx;

									const negItemDisc =
										(negOrderItem?.[el?.ID]?.orderItemDiscBaseAmount ?? 0) +
										(negOrderItem?.[el?.ID]?.orderItemDiscTaxAmount ?? 0);

									const negOrderDisc =
										(negOrderItem?.[el?.ID]?.orderDiscountItemBaseAmount ?? 0) +
										(negOrderItem?.[el?.ID]?.orderDiscountItemTaxAmount ?? 0);

									const discount = oid ? oid - negItemDisc : odi - negOrderDisc;

									return (
										<ListItem>
											<ListItemText
												primary={
													<>
														<Grid
															container
															className="xsTitle"
															style={{
																textDecoration: el?.refID
																	? 'line-through'
																	: null,
															}}
														>
															<Grid item xs={1} style={{ textAlign: 'center' }}>
																{`${index + 1}. `}
															</Grid>
															<Grid item xs={8} className="flex-space">
																{`${el?.menuItem?.name} x${Math.abs(
																	el?.quantity -
																		negOrderItem[el?.ID]?.quantity ||
																		el?.quantity,
																)}`}
															</Grid>
															<Grid
																item
																xs={3}
																style={{
																	textAlign: 'right',
																	paddingRight: '10px',
																}}
															>
																{`${getOutlet[0]?.currencyCode}${amtStr(
																	new BigNumber(baseItemAmountBill)
																		.minus(negBaseItemAmountBill ?? 0)
																		// .minus(discount ?? 0)
																		.toNumber(),
																)}`}
															</Grid>
														</Grid>
													</>
												}
												secondary={
													el?.orderItemOption?.length
														? el?.orderItemOption?.map(v => (
																<>
																	{v?.orderItemOptionItem?.map(e => {
																		let totalTax = 0;
																		e?.orderItemOptionItemTax?.map(x => {
																			totalTax += x?.taxAmount;
																		});

																		const baseOptItemAmountBill =
																			e?.basePrice + totalTax;

																		const negOptBaseItemAmountBill =
																			(negOrderItem?.[
																				el?.ID
																			]?.orderItemOptions?.filter(
																				noi => noi.optionID === v?.optionID,
																			)[0]?.orderItemOptionItem?.basePrice ??
																				0) +
																			(negOrderItem?.[
																				el?.ID
																			]?.orderItemOptions?.filter(
																				noi => noi.optionID === v?.optionID,
																			)[0]?.orderItemOptionItem?.taxAmount ??
																				0);

																		let totalOptItemOIDTax = 0;
																		e?.orderItemOptionItemDiscountTax?.map(
																			v => {
																				totalOptItemOIDTax += v?.taxAmount;
																			},
																		);

																		let totalOptItemODITax = 0;
																		e?.orderDiscountItemOptionTax?.map(v => {
																			totalOptItemODITax += v?.taxAmount;
																		});

																		const discOptItemAmountBill =
																			e?.discountBaseAmount +
																			totalOptItemOIDTax +
																			totalOptItemODITax;

																		const negDiscOptBaseItemAmountBill =
																			(negOrderItem?.[
																				el?.ID
																			]?.orderItemOptions?.filter(
																				noi => noi.optionID === v?.optionID,
																			)[0]?.orderItemOptionItem
																				?.discountBaseAmount ?? 0) +
																			(negOrderItem?.[
																				el?.ID
																			]?.orderItemOptions?.filter(
																				noi => noi.optionID === v?.optionID,
																			)[0]?.orderItemOptionItem
																				?.itemDiscountTaxAmount ?? 0) +
																			(negOrderItem?.[
																				el?.ID
																			]?.orderItemOptions?.filter(
																				noi => noi.optionID === v?.optionID,
																			)[0]?.orderItemOptionItem
																				?.discountItemTaxAmount ?? 0);

																		const optItemDiscount =
																			discOptItemAmountBill -
																			negDiscOptBaseItemAmountBill;
																		return (
																			<Grid
																				container
																				className="mdDesc"
																				style={{
																					textDecoration: el?.refID
																						? 'line-through'
																						: null,
																				}}
																			>
																				<Grid item xs={1}></Grid>
																				<Grid
																					item
																					xs={8}
																					className="flex-space"
																				>
																					{e?.optionItem?.name}
																				</Grid>
																				<Grid
																					item
																					xs={3}
																					style={{
																						textAlign: 'right',
																						paddingRight: '10px',
																					}}
																				>
																					{`${
																						getOutlet[0]?.currencyCode
																					}${amtStr(
																						new BigNumber(baseOptItemAmountBill)
																							.minus(
																								negOptBaseItemAmountBill ?? 0,
																							)
																							.toNumber(),
																					)}`}
																				</Grid>
																			</Grid>
																		);
																	})}
																</>
														  ))
														: el?.orderItemSetMenuOption?.map(v => (
																<>
																	{v?.orderItemSetMenuOptionItem?.map(e => (
																		<Grid
																			container
																			className="mdDesc"
																			style={{
																				textDecoration: el?.refID
																					? 'line-through'
																					: null,
																			}}
																		>
																			<Grid item xs={1}></Grid>
																			<Grid item xs={6} className="flex-space">
																				{e?.setMenuOptionItem?.menuItem?.name}
																			</Grid>
																			<Grid
																				item
																				xs={4}
																				style={{
																					textAlign: 'right',
																					paddingRight: '10px',
																				}}
																			>
																				{/* {amtStr(e?.price)} */}
																			</Grid>
																			<Grid item xs={1}></Grid>
																		</Grid>
																	))}
																</>
														  ))
												}
											/>
											{el?.refID ? null : (
												<ListItemSecondaryAction>
													<IconButton
														edge="end"
														aria-label="more"
														aria-controls="menu-list"
														aria-haspopup="true"
														onClick={e => {
															setOpenDialog(true);
															loadReason({
																variables: {
																	outletID: outletID,
																},
															});
															handleClickDialog(e, el?.ID, index, el);
														}}
														disabled={handlePermDisabled({
															outletID: outletID,
															permEnum:
																PosPermission.TakeawayTakeawayVoidOrderItemVoid,
														})}
													>
														<DeleteIcon fontSize="small" />
													</IconButton>
												</ListItemSecondaryAction>
											)}
										</ListItem>
									);
								})}
								<ListItem style={{ paddingRight: '0px' }}>
									<BillFooter
										style={{
											width: '95%',
											paddingLeft: '1%',
										}}
										isConfirmOrder
										subtotal={fs}
										tax={ta}
										discount={fd}
										total={to}
										outletID={outletID}
										currencyCode={getOutlet[0]?.currencyCode}
										taxSchemeInfo={getTakeawayOrder[0]?.taxSchemeInfo}
										orderID={getTakeawayOrder[0]?.ID}
										taxSchemeFooterInfo={
											getTakeawayOrder[0]?.taxSchemeFooterInfo
										}
										takeaway={true}
									/>
								</ListItem>
							</List>
						</>
					))}
			</ContentWrapper>
			<div style={{ marginBottom: '100px' }}></div>

			{mode === 'detail' ? (
				<TakeawayFooter
					pickupDialog={setPickupDialog}
					cancelDialog={setCancelDialog}
					paymentDialog={setOpenPayment}
					addOrderPath={`/menu/outlet-app/${outletID}/order/${orderID}/takeaway`}
					discountPath={`/menu/outlet-app/${outletID}/order/${orderID}/item-discount/takeaway`}
					outstandingAmount={to}
					orderStatus={selectedOrder?.status}
					outletID={outletID}
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
				/>
			) : (
				<DynamicFooter
					options={[
						{
							name: 'Make Payment',
							onClick: () => {
								setOpenPayment(true);
							},
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
						{
							name: 'Add Order',
							onClick: () => {
								localStorage.removeItem('searchFilter');

								history.push({
									pathname: `/menu/outlet-app/${outletID}/order/${orderID}/takeaway`,
								});
							},
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
					]}
				/>
			)}

			<PaymentDialogComponent
				paymentType="takeAway"
				openDialog={openPayment}
				setOpenDialog={setOpenPayment}
				getDefaultPayment={getDefaultPayment}
				errors={errors}
				control={control}
				register={register}
				watch={watch}
				setValue={setValue}
				getValue={getValues}
				getOutlet={getOutlet}
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				integrateCityLedger={getOutlet[0]?.integrateCityLedger}
				outletID={outletID}
				defaultNonGuest={defaultNonGuest}
				setDefaultNonGuest={setDefaultNonGuest}
				defaultRoom={defaultRoom}
				setDefaultRoom={setDefaultRoom}
				defaultFolio={defaultFolio}
				setDefaultFolio={setDefaultFolio}
				defaultDebtor={defaultDebtor}
				setDefaultDebtor={setDefaultDebtor}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
				selectedNonGuest={selectedNonGuest}
				setSelectedNonGuest={setSelectedNonGuest}
				selectedFolio={selectedFolio}
				setSelectedFolio={setSelectedFolio}
				selectedDebtor={selectedDebtor}
				setSelectedDebtor={setSelectedDebtor}
				selectedOrder={selectedOrder}
				total={to}
				radioError={radioError}
				setRadioError={setRadioError}
				handleSubmit={handleSubmit}
				onSubmit={onSubmitPayment}
				setOpenSignature={setOpenSignature}
				selectedPaymentClass={selectedPaymentClass}
				defaultOfficer={defaultOfficer}
				setDefaultOfficer={setDefaultOfficer}
				defaultStaff={defaultStaff}
				setDefaultStaff={setDefaultStaff}
				selectedOfficer={selectedOfficer}
				setSelectedOfficer={setSelectedOfficer}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				setHotelGuestInfo={setHotelGuestInfo}
				setReceivedAmount={setReceivedAmount}
				receivedAmount={receivedAmount}
				reset={reset}
				minVal={minVal}
				setMinVal={setMinVal}
				setChangeAmount={setChangeAmount}
				loading1={paymentQuantityLoading}
				loading2={createOnlinePaymentLoading}
				lastArray={lastArray}
				setSelectedCredit={setSelectedCredit}
				selectedCredit={selectedCredit}
			/>

			<SignatureDialog
				openSignature={openSignature}
				setOpenSignature={setOpenSignature}
				setOpenDialog={setPaymentDialog}
				isTakeaway
				selectedOrder={selectedOrder}
				total={to}
				handleSubmit={handleSubmit}
				onSubmit={onSubmitPayment}
			/>

			<CommonDialogV2
				fullWidth={true}
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
					setDeductionQuantity(1);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Void Item</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">
								<Grid container className="mdDesc" style={{ marginTop: '5px' }}>
									<Grid item xs={8} className="flex-space">
										<span style={{ fontWeight: 'bold' }}>
											{`${menu?.obj?.menuItem?.name} x${deductionQuantity}`}
										</span>
										{menu?.obj?.orderItemOption
											? menu?.obj?.orderItemOption?.map(v => (
													<>
														{v?.orderItemOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.optionItem?.name}
															</div>
														))}
													</>
											  ))
											: menu?.obj?.orderItemSetMenuOption?.map(v => (
													<>
														{v?.orderItemSetMenuOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.setMenuOptionItem?.menuItem?.name}
															</div>
														))}
													</>
											  ))}
									</Grid>
									<Grid
										item
										xs={4}
										style={{
											textAlign: 'right',
											paddingRight: '10px',
											fontSize: '15px',
										}}
									>
										<IndeterminateCheckBoxIcon
											style={{
												fontSize: '29px',
												paddingInline: '10px',
											}}
											className="order-minus"
											onClick={() => {
												clickButton('reduce');
											}}
										/>
										<span
											className="order-quantity"
											style={{
												fontSize: '25px',
												position: 'relative',
												bottom: '5px',
											}}
										>
											{deductionQuantity}
										</span>
										<AddBox
											style={{
												fontSize: '29px',
												color: '#ff9800',
												paddingInline: '10px',
											}}
											className="order-add"
											onClick={() => {
												clickButton('add');
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											as={
												<TextField
													select
													size="small"
													label="Reason"
													InputLabelProps={{ style: { fontSize: 12 } }}
												>
													{getReason?.map((x, index) => (
														<MenuItem key={index} value={x?.name}>
															{x?.name}
														</MenuItem>
													))}
												</TextField>
											}
											name="reasonCode"
											autoComplete="off"
											control={control}
											multiline
											fullWidth
											required
											margin="normal"
										></Controller>
									</Grid>
								</Grid>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
										setDeductionQuantity(1);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(onSubmit)();
										setDeductionQuantity(1);
									},
									variant: 'contained',
									color: color(
										watch('reasonCode') === undefined,
										voidedOrderItemWithOptionsLoading ||
											voidedOrderItemWithSetMenuOptionsLoading,
									),
									disabled: disable(
										watch('reasonCode') === undefined,
										voidedOrderItemWithOptionsLoading ||
											voidedOrderItemWithSetMenuOptionsLoading,
									),
								},
							},
						],
					},
				}}
			/>

			<CommonDialogV2
				fullWidth={true}
				open={pickupDialog}
				onClose={() => {
					setPickupDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="highlight-text title">Pick Up</div>
									</div>
								</div>
								<div className="infoline-content">
									<div className="xsTitle infoline">{`#${selectedOrder?.docNo} |
							${selectedOrder?.patronName}`}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">Confirm pick up?</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setPickupDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										onSubmitPickup();
									},
									variant: 'contained',
									color: loadingPickupTakeawayOrder ? 'inherit' : 'primary',
									disabled: loadingPickupTakeawayOrder ? true : false,
								},
							},
						],
					},
				}}
			/>

			<CommonDialogV2
				fullWidth={true}
				open={cancelDialog}
				onClose={() => {
					setCancelDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="highlight-text title">Cancel Order</div>
									</div>
								</div>
								<div className="infoline-content">
									<div className="xsTitle infoline">{`#${selectedOrder?.docNo} |
							${selectedOrder?.patronName}`}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">Confirm to cancel all orders?</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setCancelDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										onSubmitCancel();
									},
									variant: 'contained',
									color: loadingCancelTakeawayOrder ? 'inherit' : 'primary',
									disabled: loadingCancelTakeawayOrder ? true : false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
